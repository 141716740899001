@import '../../../theme';

.icon-text-box {
  display: flex;
  align-items: center;
  border-radius: $border-radius-xl;
  padding: $spacing-md;
  height: 40px;
  width: auto;
  transition: $transition-default;
  background-color: rgb(189, 190, 190);
  color: black;
  justify-content: space-evenly;
  box-shadow: $box-shadow-medium;
  text-align: left;

  @media (max-width: 768px) {
    padding: $spacing-sm;
    height: auto;
    flex-wrap: wrap;
  }

  @media (max-width: 480px) {
    padding: $spacing-xs;
  }

  &.primary {
    background-color: var(--primary-color);
    color: inherit;

    &:hover {
      background-color: var(--primary-color-hover);
    }
  }

  &.secondary {
    background-color: var(--secondary-color);
    color: var(--text-color-secondary);

    &:hover {
      background-color: var(--secondary-color-hover);
    }
  }

  &.success {
    background-color: $success-color;
    color: white;

    &:hover {
      background-color: darken($success-color, 10%);
    }
  }

  &.error {
    background-color: $error-color;
    color: white;

    &:hover {
      background-color: darken($error-color, 10%);
    }
  }

  &.warning {
    background-color: $warning-color;
    color: white;

    &:hover {
      background-color: darken($warning-color, 10%);
    }
  }

  &.disabled {
    background-color: $disabled-color;
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.6;
  }

  .icon-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 5px;

    svg {
      width: 15px;
      height: 15px;

      @media (max-width: 768px) {
        width: 18px;
        height: 18px;
      }

      @media (max-width: 480px) {
        width: 15px;
        height: 15px;
      }
    }
  }

  .text-input {
    border: none;
    outline: none;
    flex: 1;
    padding: $spacing-xs;
    text-align: left;
    font-size: $font-size-base;
    color: var(--text-color);
    border-radius: $border-radius-lg;
    background-color: rgb(189, 190, 190);

    &::placeholder {
      color: inherit;
      opacity: 1;
    }

    @media (max-width: 768px) {
      font-size: $font-size-sm;
      width: 100%;
    }

    @media (max-width: 480px) {
      font-size: $font-size-xs;
    }

    &.disabled {
      background-color: $disabled-color;
    }
  }

  .toggle-password {
    background: none;
    border: none;
    cursor: pointer;
    padding: 1px;
    margin: 5px;
    outline: none;
    border-radius: 50%;
    color: inherit;

    &:focus {
      outline: 2px solid white;
      outline-offset: 2px;
    }

    svg {
      width: 20px;
      height: 20px;

      @media (max-width: 768px) {
        width: 18px;
        height: 18px;
      }

      @media (max-width: 480px) {
        width: 15px;
        height: 15px;
      }
    }

    &:hover {
      color: black;
    }

    @media (max-width: 480px) {
      margin-top: $spacing-xs;
    }
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
}
