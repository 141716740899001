// Colors
$primary-color: #bcf542;
$secondary-color: #fafd36;
$background-color: #1b1b1b;
$background-color-dark: darken($background-color, 20%);
$background-color-light: lighten($background-color, 20%);
$background-primary-light-color: lighten($primary-color, 10%);
$background-secondary-light-color: lighten($secondary-color, 10%);
$background-light-color: #f0f0f0;
$text-color: black;
$text-color-light: rgb(199, 197, 197);
$text-color-dark: rgb(43, 43, 43);
$text-color-primary: #ffffff;
$text-color-secondary: #1b1b1b;
$error-color: #ff0202;
$success-color: #9ff863dd;
$warning-color: #d38d0a;
$disabled-color: #b8b8b8dd;
$link-color: $primary-color;
$link-hover-color-primary: mix(black, $primary-color, 20%);
$link-hover-color-secondary: mix(black, $secondary-color, 20%);

// Fonts
$font-family-primary: 'Montserrat', Arial, sans-serif;

$font-size-xxs: clamp(0.6rem, 1rem + 0.15vw, 1rem); 
$font-size-xs: clamp(0.875rem, 1rem + 0.25vw, 1rem);   
$font-size-sm: clamp(1rem, 1.125rem + 0.5vw, 1.125rem);    
$font-size-md: clamp(1.125rem, 1.25rem + 0.5vw, 1.25rem);   
$font-size-base: clamp(1.25rem, 1.375rem + 0.75vw, 1.375rem);
$font-size-large: clamp(1.5rem, 1.75rem + 1vw, 1.75rem);    
$font-size-heading: clamp(1.875rem, 2.125rem + 1vw, 2.125rem);
$font-size-super: clamp(2.5rem, 2.9375rem + 2vw, 2.9375rem);  

$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: 900;

// Spacing (margins and paddings)
$spacing-xs: 8px;
$spacing-sm: 16px;
$spacing-md: 24px;
$spacing-lg: 32px;
$spacing-xl: 48px;

// Padding
$padding-xs: 4px;
$padding-sm: 8px;
$padding-md: 12px;
$padding-lg: 16px;

// Border Radius
$border-radius-sm: 4px;
$border-radius-md: 8px;
$border-radius-lg: 16px;
$border-radius-xl: 20px;

// Box Shadows
$box-shadow-light: 0px 2px 4px rgba(255, 255, 255, 0.774);
$box-shadow-medium: 0px 4px 8px rgba(255, 255, 255, 0.144);
$box-shadow-large: 0px 4px 8px rgba(230, 230, 230, 0.274);

// Transition
$transition-default: 0.3s ease-in-out;

// Links
$link-font-weight: $font-weight-bold;
$link-text-decoration: none;
$link-hover-decoration: underline;

// Typography
h1 {
  font-size: $font-size-heading;
  font-weight: $font-weight-bold;
  margin-bottom: $spacing-sm;
}

h2 {
  font-size: $font-size-large;
  font-weight: $font-weight-bold;
  margin-bottom: $spacing-sm;
}

p {
  font-size: $font-size-base;
  line-height: 1.5;
  margin-bottom: $spacing-md;
}

// General Layout
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 $spacing-md;
}

.section {
  padding: $spacing-lg 0;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid {
  display: grid;
  gap: $spacing-md;
}
