@import '../../../theme';

.sm-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  transition: $transition-default;
  text-decoration: none;
  color: inherit;
  box-shadow: $box-shadow-medium;

  &.primary {
    background-color: var(--primary-color);
    color: var(--text-color-primary);

    &:hover {
      background-color: var(--primary-color-hover);
    }
  }

  &.secondary {
    background-color: var(--secondary-color);
    color: var(--text-color-secondary);

    &:hover {
      background-color: var(--secondary-color-hover);
    }
  }

  &.success {
    background-color: $success-color;

    &:hover {
      background-color: darken($success-color, 10%);
    }
  }

  &.error {
    background-color: $error-color;

    &:hover {
      background-color: darken($error-color, 10%);
    }
  }

  &.warning {
    background-color: $warning-color;

    &:hover {
      background-color: darken($warning-color, 10%);
    }
  }

  &.clear {
    background-color: transparent;
    box-shadow: none;

    &:hover {
      box-shadow: $box-shadow-light;
    }
  }

  &.disabled {
    background-color: $disabled-color;
    cursor: not-allowed;
    pointer-events: none; 
    opacity: 0.6; 
  }

  &:hover {
    transform: scale(1.1);
    text-decoration: none;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }

  &:focus {
    outline: 2px solid $disabled-color; 
    outline-offset: 2px;
  }

  svg {
    height: 20px;
    width: 20px;
  }
}

