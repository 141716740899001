@import "../../../theme";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2001;
  overflow: hidden;
  padding: 0;
}

.modal__content {
  background-color: var(--background-color-lighter);
  border-radius: $border-radius-lg;
  color: var(--primary-text-color);
  max-width: 800px;
  width: 90%;
  max-height: 90vh;
  box-shadow: $box-shadow-large;
  display: flex;
  flex-direction: column;
  animation: modal-enter 0.3s ease-in-out;
  overflow: hidden;

  @media (max-width: 900px) {
    width: 95%;
    max-width: none;
    height: auto;
    max-height: 85vh;
  }

  @media (max-width: 600px) {
    width: 100%;
    max-height: 100vh;
    border-radius: 0;
  }
}

.modal__header {
  background-color: var(--background-color-dark);
  color: var(--text-color-primary);
  padding: $spacing-sm;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--background-color-light);

  h1, h2 {
    font-size: $font-size-large;
    margin: 0;
    font-weight: $font-weight-bold;

    @media (max-width: 768px) {
      font-size: $font-size-base;
    }
  }
}

.modal__body {
  font-size: $font-size-base;
  color: var(--text-color-primary);
  overflow-y: auto;
  padding: $spacing-md;
  flex-grow: 1; // Allow body to expand within modal

  // Custom scrollbar styles
  scrollbar-width: thin;
  scrollbar-color: var(--primary-color) var(--background-color-light);

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: $border-radius-sm;
  }

  &::-webkit-scrollbar-track {
    background: var(--background-color-light);
  }

  @media (max-width: 768px) {
    font-size: $font-size-sm;
    padding: $spacing-sm;
  }
}

.modal__footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: $spacing-sm;
  gap: $spacing-xs;

  button {
    font-size: $font-size-sm;
    padding: $spacing-xs $spacing-sm;
    min-width: 120px; // Ensure buttons are large enough to tap on mobile

    @media (max-width: 600px) {
      width: 100%; // Make buttons full-width on small screens
    }
  }

  @media (max-width: 600px) {
    flex-direction: column; // Stack buttons on small screens
    gap: $spacing-sm;
  }
}

/* Smooth modal entrance animation */
@keyframes modal-enter {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
