@import '../../theme';

.footer {
  padding: $spacing-md $spacing-sm;
  text-align: center;
  width: 100%;
  background-color: darken($background-color, 10%);
  box-shadow: 0 -2px 10px rgba($text-color-secondary, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;

  .footer-links {
    display: flex;
    justify-content: center;
    gap: $spacing-md;

    .footer-link {
      transition: transform $transition-default;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .footer-copyright {
    font-size: $font-size-sm;
    margin-top: $spacing-xs;
    line-height: 1.4;
    color: lighten($text-color-primary, 10%);

    .credit-footer {
      font-size: $font-size-xs;
      color: lighten($text-color-primary, 20%);

      a {
        text-decoration: none;
        color: $primary-color;
        font-weight: $font-weight-bold;
        transition: color $transition-default;

        &:hover {
          color: darken($primary-color, 10%);
        }
      }
    }
  }

  .captcha-container {
    p {
      font-size: $font-size-xs;
      color: lighten($text-color-primary, 20%);
    }

    a {
      text-decoration: underline;
      color: $link-color;
      transition: color $transition-default;

      &:hover {
        color: $link-hover-color-primary;
      }
    }
  }
}

@media (max-width: 768px) {
  .footer {
    padding: $spacing-sm;
    gap: $spacing-sm;

    .footer-links {
      gap: $spacing-sm;
    }

    .footer-copyright {
      font-size: $font-size-xs;
    }

    .captcha-container {
      font-size: $font-size-xxs;
    }
  }
}

@media (max-width: 1440px) {
  .footer {
    display: none;
  }
}