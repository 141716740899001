.skeleton-loader {
    background-color: #e0e0e0;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    margin: auto;
  
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: -100%;
      height: 100%;
      width: 100%;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgb(158, 158, 158) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      animation: skeleton-loading 1.5s infinite;
    }
  }
  
  @keyframes skeleton-loading {
    0% {
      left: -100%;
    }
    100% {
      left: 100%;
    }
  }
  