@import "../../../theme";

.select-main {
  position: relative;
  width: auto;
  margin: 10px 0;
  height: 35px;
  border-radius: $border-radius-lg;
  box-shadow: $box-shadow-medium;
  transition: $transition-default;
  color: $text-color;

  &:hover {
    box-shadow: $box-shadow-large;
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  .select {
    width: 100%;
    padding: 8px 25px;
    appearance: none;
    border-radius: $border-radius-lg;
    font-weight: $font-weight-bold;
    font-size: $font-size-sm;
    border: none;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    background-color: var(--background-light-color);
    cursor: pointer;

    &:focus {
      border-color: var(--primary-color);
      outline: none;
    }

    &.primary {
      background-color: var(--primary-color);
      color: var(--text-color-primary);

      &:hover {
        background-color: var(--primary-color);
      }
    }

    &.secondary {
      background-color: var(--secondary-color);
      color: var(--text-color-secondary);

      &:hover {
        background-color: var(--secondary-color);
      }
    }

    &.success {
      background-color: $success-color;
    }
  
    &.error {
      background-color: $error-color;
    }
  
    &.warning {
      background-color: $warning-color;
    }
  
    &.disabled {
      background-color: $disabled-color;
      cursor: not-allowed;
      pointer-events: none; 
      opacity: 0.6; 
    }

    &.disabled {
      background-color: $disabled-color;
      cursor: not-allowed;
    }

    &::placeholder {
      color: $disabled-color;
      opacity: 0.7;
    }
  }

  .select-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    pointer-events: none;
    font-size: $font-size-sm;
    transform-origin: center;

    &.primary {
      color: var(--text-color-primary);
    }

    &.secondary {
      color: var(--text-color-secondary);
    }

    &.disabled {
      color: $disabled-color;
    }
  }
}
