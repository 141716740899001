@import "../../../theme";

.loading-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  animation: fade-in 1s ease-in-out;
}

.dashboard-stats {
  padding: 20px;
  background-color: $background-light-color;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-family: $font-family-primary;
    font-size: $font-size-heading;
    font-weight: $font-weight-bold;
    color: $text-color-secondary;
    text-align: center;
    margin-bottom: 40px;
  }

  .stats-summary {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    width: 100%;
    max-width: 1200px;

    .stat-item {
      background-color: $background-color;
      border: 1px solid $background-color-dark;
      border-radius: $border-radius-md;
      padding: $padding-lg;
      text-align: center;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      box-shadow: $box-shadow-large;

      &:hover {
        transform: translateY(-5px);
        box-shadow: $box-shadow-medium;
      }

      h3 {
        font-family: $font-family-primary;
        font-size: $font-size-md;
        font-weight: $font-weight-bold;
        color: $text-color-primary;
        margin-bottom: 10px;
      }

      p {
        font-family: $font-family-primary;
        font-size: $font-size-sm;
        color: $text-color-primary;
        margin: 5px 0;
        line-height: 1.5;
      }
    }
  }


  .error-message {
    font-family: $font-family-primary;
    font-size: $font-size-md;
    color: $error-color;
    text-align: center;
    margin-top: 20px;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: $font-size-large;
    }

    .stats-summary {
      grid-template-columns: 1fr;
    }
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.attendance-chart {
  margin-top: 40px;
  width: 90%;

  h3 {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  canvas {
    max-width: 100%;
    height: auto;
  }
}
