@import "../../../theme";

.images-section {
  padding: $spacing-lg;
  background-color: $background-light-color;
  min-height: 100vh;

  .section-header {
    margin-bottom: $spacing-md;
    text-align: center;

    h2 {
      font-family: $font-family-primary;
      font-size: $font-size-large;
      font-weight: $font-weight-bold;
      color: $text-color-secondary;

      @media (max-width: 768px) {
        font-size: $font-size-md;
      }

      @media (max-width: 480px) {
        font-size: $font-size-sm;
      }
    }

    p {
      font-family: $font-family-primary;
      font-size: $font-size-md;
      color: $text-color-secondary;
      margin-top: $spacing-sm;

      @media (max-width: 768px) {
        font-size: $font-size-sm;
      }

      @media (max-width: 480px) {
        font-size: $font-size-xs;
      }
    }
  }

  .add-image-section {
    display: flex;
    justify-content: center;
    margin-bottom: $spacing-md;
    border-bottom: 1px solid $disabled-color;

    @media (max-width: 768px) {
      justify-content: center;
    }
  }

  .search-section {
    display: flex;
    justify-content: center;
    gap: $padding-lg;
    align-items: center;
    margin-bottom: $spacing-md;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: $spacing-sm;
    }

    .search-input {
      width: 60%;
      padding: $spacing-sm;
      font-family: $font-family-primary;
      font-size: $font-size-md;
      color: $text-color-secondary;
      border: 1px solid $disabled-color;
      border-radius: $border-radius-sm;
      transition: border-color $transition-default;

      &:focus {
        border-color: $primary-color;
        outline: none;
      }

      @media (max-width: 768px) {
        width: 100%;
        font-size: $font-size-sm;
      }

      @media (max-width: 480px) {
        font-size: $font-size-xs;
      }
    }
  }

  .image-grid-section {
    margin-top: $spacing-md;
    

    .image-grid {
      display: grid;
      padding: 0px !important;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: $spacing-md;

      @media (max-width: 768px) {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: $spacing-sm;
      }

      @media (max-width: 480px) {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        gap: $spacing-xs;
      }

      li {
        list-style: none;
        border-radius: $border-radius-sm;
        overflow: hidden;
        cursor: pointer;
        transition: transform $transition-default, box-shadow $transition-default;

        img {
          width: 100%;
          height: auto;
          display: block;
        }

        &:hover {
          transform: scale(1.05);
          box-shadow: $box-shadow-medium;
        }
      }
    }

    .no-images-message {
        text-align: center;
        font-family: $font-family-primary;
        font-size: $font-size-md;
        color: $disabled-color;

      @media (max-width: 768px) {
        font-size: $font-size-sm;
      }

      @media (max-width: 480px) {
        font-size: $font-size-xs;
      }
    }
  }
}
