@import "../../../../theme";

.contract-form {
    display: flex;
    flex-direction: column;
    gap: $spacing-md;
    color: $text-color-primary;
    padding: $spacing-lg;
    border-radius: $border-radius-md;
    box-shadow: $box-shadow-medium;
    font-family: $font-family-primary;
  
    h3 {
      font-size: $font-size-md;
      font-weight: $font-weight-bold;
      margin-bottom: $spacing-sm;
    }
  
    textarea {
      width: 100%;
      padding: $padding-md;
      margin-bottom: $spacing-sm;
      border: 1px solid $text-color-secondary;
      border-radius: $border-radius-sm;
      background-color: $background-color;
      font-size: $font-size-base;
      color: $text-color-primary;
      font-family: $font-family-primary;
      transition: border-color $transition-default;
  
      &:focus {
        border-color: $primary-color;
        outline: none;
      }
    }
  
    textarea {
      resize: vertical;
      min-height: 100px;
    }
  
    .form-actions {
      display: flex;
      justify-content: flex-end;
      gap: $spacing-sm;

    }

    label {
        font-size: $font-size-sm;
    }
  }
  
  .modal {
    color: $text-color-dark;
    border-radius: $border-radius-md;
    padding: $spacing-lg;
    box-shadow: $box-shadow-large;
    font-family: $font-family-primary;
  }