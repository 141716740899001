@import '../../../../theme';

.image-view-box {

  .modal__content {
    

    .modal__body {
      display: flex;
      flex-direction: column;
      height: auto;
    }
  }
}

.viewbox-image {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    padding: 0px;
    margin: 0px;
  }
}

.viewbox-footer {
  padding: $spacing-sm;
  text-align: center;
  font-size: $font-size-base;
  color: $text-color-primary;

  p {
    margin: 0;

    a {
      color: $link-color;
      text-decoration: none;
      font-weight: $font-weight-bold;

      &:hover {
        color: $link-hover-color-primary;
        text-decoration: underline;
      }
    }
  }
}
