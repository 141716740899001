@import "../../theme";

.video-upload {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
  background-color: $background-light-color;
  padding: $spacing-lg;
  border-radius: $border-radius-lg;
  box-shadow: $box-shadow-medium;

  h2 {
    font-family: $font-family-primary;
    font-size: $font-size-large;
    color: $text-color-secondary;
    text-align: center;
    margin-bottom: $spacing-md;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    gap: $spacing-sm;

    textarea {
      width: 100%;
      min-height: 100px;
      font-family: $font-family-primary;
      font-size: $font-size-md;
      color: $text-color-secondary;
      border: 1px solid $disabled-color;
      border-radius: $border-radius-sm;
      padding: $spacing-sm;
      resize: vertical;
      transition: border-color $transition-default, box-shadow $transition-default;

      &:focus {
        border-color: $primary-color;
        box-shadow: 0 0 5px $primary-color;
        outline: none;
      }

      &:disabled {
        background-color: $disabled-color;
        cursor: not-allowed;
        opacity: 0.6;
      }

      &::placeholder {
        color: $disabled-color;
        opacity: 0.8;
      }
    }

    .error-message {
      font-family: $font-family-primary;
      font-size: $font-size-sm;
      color: $error-color;
      margin-top: $spacing-xs;
    }
  }

  button {
    align-self: center;

    &:disabled {
      background-color: $disabled-color;
      cursor: not-allowed;
    }
  }
}

@media (max-width: 768px) {
  .video-upload {
    padding: $spacing-md;

    .form-group {
      textarea {
        min-height: 80px;
      }
    }
  }
}
