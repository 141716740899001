@import "../../theme";

.dashboard-toolbar {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: $background-primary-light-color;
  padding: $spacing-md;

  .toolbar-header {
    margin-bottom: $spacing-sm;

    h2 {
      font-family: $font-family-primary;
      font-size: $font-size-large;
      font-weight: $font-weight-bold;
      color: $text-color-secondary;
      margin: 5px;
    }
  }

  .toolbar-links {
    display: flex;
    justify-content: center;
    gap: $spacing-sm;

    .tab {
      text-decoration: none;
      color: $text-color-secondary;
      font-family: $font-family-primary;
      font-size: $font-size-md;
      font-weight: $font-weight-normal;
      text-align: center;
      padding: $spacing-sm;
      background-color: transparent;
      border-radius: $border-radius-sm;
      transition: background-color $transition-default, color $transition-default;

      &:hover {
        background-color: lighten(black, 20%);
        color: $text-color-primary;
      }
    }

    .active-tab {
      text-decoration: none;
      background-color: black;
      color: $text-color-primary;
      font-weight: $font-weight-bold;
      padding: $spacing-sm;
      border-radius: $border-radius-sm;
      box-shadow: $box-shadow-medium;
    }
  }

  /* Responsive Styling */
  @media (max-width: 768px) {
    flex-direction: column;

    .toolbar-links {
      flex-direction: column;
      align-items: center;
      gap: $spacing-xs;

      .tab {
        font-size: $font-size-sm;
        padding: $spacing-xs;
        width: 100%;
        text-align: center;
      }

      .active-tab {
        font-size: $font-size-sm;
        padding: $spacing-xs;
        width: 100%;
        text-align: center;
      }
    }

    .toolbar-header h2 {
      font-size: $font-size-md;
    }
  }

  @media (max-width: 480px) {
    padding: $spacing-sm;

    .toolbar-header h2 {
      font-size: $font-size-base;
    }

    .toolbar-links {
      .tab {
        font-size: $font-size-sm;
        padding: $spacing-xs;
      }

      .active-tab {
        font-size: $font-size-sm;
        padding: $spacing-xs;
      }
    }
  }
}
