@import "../../../../theme";

.edit-youtube-video-modal {
  display: flex;
  justify-content: center;
  align-items: center;


  .modal-content {
    background-color: $background-color-light;
    width: 90%;

    h2 {
      font-family: $font-family-primary;
      font-size: $font-size-large;
      font-weight: $font-weight-bold;
      color: white;
      margin-bottom: $spacing-md;
    }

    .form-group {
      margin-bottom: $spacing-md;

      label {
        display: block;
        font-family: $font-family-primary;
        font-size: $font-size-md;
        color: white;
        margin-bottom: $spacing-xs;
      }

      textarea {
        width: 100%;
        min-height: 100px;
        font-family: $font-family-primary;
        font-size: $font-size-md;
        color: $text-color-secondary;
        border: 1px solid $disabled-color;
        border-radius: $border-radius-sm;
        padding: $spacing-sm;
        resize: vertical;
        transition: border-color $transition-default, box-shadow $transition-default;
  
        &:focus {
          border-color: $primary-color;
          box-shadow: 0 0 5px $primary-color;
          outline: none;
        }
  
        &:disabled {
          background-color: $disabled-color;
          cursor: not-allowed;
          opacity: 0.6;
        }
  
        &::placeholder {
          color: $disabled-color;
          opacity: 0.8;
        }
      }
    }

    .modal-actions {
      display: flex;
      justify-content: space-between;
      gap: $spacing-sm;

      button {
        flex: 1;
        font-size: $font-size-xs;
      }
    }
  }
}
