@import "../../theme";

.calendar-page {
    background-image: url('../../Assets/img/eVENTS.jpg');
    background-repeat: repeat;
    background-size: contain;
    color: $text-color-primary;
    font-family: $font-family-primary;
    padding: $spacing-lg;
    min-height: 100vh;
    margin-top: 50px;

    @media (max-width: 1024px) {
        padding: none;
    }

    @media (max-width: 768px) {
        padding: none !important;
    }

    .info-accordion {
        max-width: 600px;
        margin: $spacing-xs auto;

        a {
            text-decoration: none;
            color: $primary-color;
        }
    }

    .calendar-loading-container {
        text-align: center;

        p {
            font-size: $font-size-large;
            font-weight: $font-weight-bold;

            @media (max-width: 768px) {
                font-size: $font-size-base;
            }
        }
    }

    .fc {
        background-color: $background-color;
        border-radius: $border-radius-md;
        height: 100vh;
        padding: $padding-lg;

        @media (max-width: 768px) {
            padding: $padding-xs;
        }

        .fc-toolbar {
            margin-bottom: $spacing-md;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            @media (max-width: 768px) {
                margin-bottom: $spacing-sm;
                flex-direction: column;
                align-items: center;
                text-align: center;

                button {
                    margin: $spacing-xs 0;
                }
            }

            button {
                font-size: $font-size-sm;
                padding: $padding-sm $padding-md;
                border-radius: $border-radius-sm;
                background-color: $primary-color;
                color: black;
                border: none;
                cursor: pointer;
                transition: background-color $transition-default;

                &:hover {
                    background-color: $link-hover-color-primary;
                }

                &:disabled {
                    background-color: $disabled-color;
                    cursor: not-allowed;
                }
            }
        }

        .fc-daygrid-day {
            &.fc-day-today {
                background-color: $background-color-light;
            }

            &.fc-day-past {
                color: $text-color-light;
            }
        }

        .fc-col-header-cell-cushion {
            @media (max-width: 768px) {
                font-size: 0.9rem;
                text-align: center;
            }
        }

        .fc-daygrid-day-number {
            @media (max-width: 768px) {
                font-size: 0.9rem;
                text-align: center;
            }
        }

        .fc-event {
            background-color: $primary-color;
            border: 1px solid $secondary-color;
            border-radius: $border-radius-sm;
            padding: $padding-xs;

            @media (max-width: 768px) {
                font-size: $font-size-sm;
            }
        }

        .fc-event-time {
            color: black;

            @media (max-width: 768px) {
                font-size: $font-size-sm;
            }
        }

        .fc-event-title {
            color: black;

            @media (max-width: 768px) {
                font-size: $font-size-sm;
            }
        }
    }

    .booking-request {
        margin-top: $spacing-lg;
        padding: $spacing-md;
        background-color: $background-color;
        border-radius: $border-radius-lg;
        text-align: center;

        @media (max-width: 768px) {
            margin-top: $spacing-md;
            padding: $spacing-sm;
        }

        h2 {
            font-size: $font-size-large;
            color: $secondary-color;

            @media (max-width: 768px) {
                font-size: $font-size-base;
            }
        }

        .error-message {
            color: $error-color;
            font-size: $font-size-sm;
            margin-top: $spacing-sm;

            @media (max-width: 768px) {
                font-size: $font-size-sm;
            }
        }
    }

    .blocked-date {
        background-color: rgba(255, 165, 0, 0.3); // Light orange background
        border: 1px solid rgba(255, 165, 0.8); // Orange border
        pointer-events: none; // Disable clicks
        cursor: not-allowed !important;
        opacity: 0.7;
    }
}

.error-page {
    text-align: center;
    margin-top: 50px;
    min-height: 100vh;
  
    h2 {
      font-size: 2rem;
      color: $error-color;
    }
  
    p {
      font-size: 1.25rem;
      color: $text-color-light;
    }
  }