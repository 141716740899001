@import '../../theme';

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing-sm;
  height: 70px;
  background-color: darken($background-color, 10%);
  box-shadow: 0 -2px 10px rgba($text-color-secondary, 0.3);
  z-index: 1000;
  overflow: hidden;

  @media (max-width: 1440px) {
    position: fixed;
  }
}

.titleImage {
  background-image: url('../../Logo.svg');
  height: 80px;
  width: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 1020px) {
    height: 60px;
    width: 60px;
  }
}

.header-nav {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  .signout-button {
    @media (max-width: 1440px) {
      display: none;
    }
  }
}

.nav-links {
  display: flex;
  gap: $spacing-sm;
  margin-left: auto;

  li {
    list-style: none;
  }

  a {
    color: $text-color-primary;
    font-family: $font-family-primary;
    text-decoration: none;
    font-size: $font-size-base;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    transition: color $transition-default;

    &:hover {
      color: $primary-color;
    }

    &.active {
      color: $primary-color;
    }
  }

  @media (max-width: 1440px) {
    display: none;
    flex-direction: column;
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: $background-color;
    padding: $spacing-sm 0;
    z-index: 999;

    &.open {
      display: flex;
    }

    li {
      margin: $spacing-sm 0;
      text-align: center;
    }
  }
}

.hamburger {
  display: none;
  font-size: 24px;
  padding: 10px;
  color: $text-color-primary;
  cursor: pointer;
  margin-left: auto;

  @media (max-width: 1440px) {
    display: block;
  }
}

.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: darken($background-color, 10%);
  z-index: 1000;
}

.mobile-nav-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  padding: $spacing-md;
  overflow-y: auto;
}

.mobile-logo {
  margin: 0 auto;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  .titleImageMobile {
    background-image: url('../../Logo.svg');
    background-size: contain; 
    background-repeat: no-repeat;
    background-position: center; 
    width: 100%; 
    height: 100%; 
  }
}


.mobile-nav-links {
  list-style: none;
  padding: 0;
  text-align: center;

  li {
    margin: $spacing-sm 0;
  }

  a {
    color: $text-color-primary;
    padding: none;
    font-family: $font-family-primary;
    text-decoration: none;
    font-size: 1.1em;
    font-weight: 800;
    text-transform: uppercase;
    transition: color $transition-default;

    &:hover {
      color: $primary-color;
    }

    &.active {
      color: $primary-color;
    }
  }
}

.mobile-social-links {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.mobile-footer {
  margin-top: $spacing-md;
  font-size: $font-size-xs;
  color: lighten($text-color-primary, 20%);
  text-align: center;

  .credit-footer {
    font-size: $font-size-xs;
  }

  a {
    text-decoration: none;
    color: $primary-color;
    transition: color $transition-default;

    &:hover {
      color: $link-hover-color-primary;
    }
  }
}
