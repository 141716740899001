@import '../../../../theme';

.event-content {
  max-height: 60vh;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.event-viewbox-image {
  max-width: 80%;
  height: auto;
}

.event-details h2 {
  margin-bottom: $spacing-xs;
  font-size: $font-size-sm;
  color: $text-color-light
}

.event-details p {
  margin-bottom: $spacing-xs;
  color: $text-color-light
}
