@import "../../../theme";

.spinner {
    display: inline-block;
    border-radius: 50%;
    border-style: solid;
    animation: spin 1s linear infinite;
  
    // Default theme
    border-color: $primary-color transparent transparent transparent;
  
    &.primary {
      border-color: $primary-color transparent transparent transparent;
    }
  
    &.secondary {
      border-color: $secondary-color transparent transparent transparent;
    }
  
    &.error {
      border-color: $error-color transparent transparent transparent;
    }
  
    &.warning {
      border-color: $warning-color transparent transparent transparent;
    }
  
    &.success {
      border-color: $success-color transparent transparent transparent;
    }
  }
  
  // Animation
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }