@import "../../theme";

.content {
  color: $text-color-primary;
  font-family: $font-family-primary;
  font-size: $font-size-base;
  line-height: 1.6;
  text-align: center;
  margin-top: 70px;
  padding: none;
  width: 100%;
  padding-bottom: 70px;

  .hero {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), 
                url("../../Assets/img/Endyz-9.jpg") center/cover no-repeat;
    height: 400px;
    display: flex;
    max-width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $text-color-light;
    margin-bottom: $spacing-lg;
  
    .hero-content {
      max-width: 600px;
      padding: $padding-xs;

      @media (max-width: 800px) {
        width: 100%;
      }
  
      h1 {
        font-size: $font-size-super;
        font-weight: $font-weight-bolder;
        color: $primary-color;
        margin-bottom: $spacing-sm;
        text-transform: uppercase;

        @media (max-width: 800px) {
          font-size: 1.5em;
          margin-bottom: $spacing-xs;
        }

        @media (max-width: 500px) {
          font-size: 1.2em;
          margin-bottom: $spacing-xs;
        }
      }
  
      p {
        font-size: $font-size-large;
        margin-bottom: $spacing-md;

        @media (max-width: 800px) {
          font-size: 1em;
          margin-bottom: $spacing-xs;
        }

        @media (max-width: 500px) {
          font-size: 1em;
          margin-bottom: $spacing-xs;
        }
      }
    }
  }
  
  @media (max-width: 768px) {
    
    .hero-content {
      h1 {
        font-size: $font-size-large;
      }
  
      p {
        font-size: $font-size-md;
      }
    }
  }
  

  .topSection {
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacing-sm;
    max-width: 90%;
    margin: $spacing-lg auto;
  }

  .events {
    background-color: $background-color;
    padding: $spacing-sm;
    border-radius: $border-radius-md;
    box-shadow: $box-shadow-medium;

    h3 {
      text-transform: uppercase;
      font-size: $font-size-large;
      margin-bottom: 0;
      color: $primary-color;
    }

    .eventList {
      max-height: 600px;
      overflow-y: auto;
      padding-right: $spacing-sm;
    }

    .eventTitle {
      font-size: $font-size-base;
      margin-bottom: 5px;
    }

    .eventDate {
      margin-bottom: 10px;
    }

    .event-image {
      margin-top: 10px;
      img {
      border-radius: $border-radius-sm;
      box-shadow: $box-shadow-light;
      }
    }

    ul {
      list-style-type: none;
      padding: 0;

      li {
        padding: $spacing-sm 2;
        border-bottom: 1px solid lighten($text-color-primary, 20%);

        &:last-child {
          border-bottom: none;
        }
      }
    }

    .no-events-message {
      text-align: center;
      margin-top: $spacing-md;
      color: $text-color-primary;
      
      img {
        margin-bottom: $spacing-sm;
      }
    
      h4 {
        font-size: $font-size-large;
        margin-bottom: $spacing-sm;
      }
    
      p {
        font-size: $font-size-base;
        margin-bottom: $spacing-md;
        color: lighten($text-color-primary, 20%);
      }
    
      button {
        margin-top: $spacing-sm;
      }
    }
  }

  .gallery {
    background-color: $background-color;
    box-shadow: $box-shadow-medium;
    border-radius: $border-radius-md;
    padding: $padding-md;
    
    h3 {
      font-size: $font-size-large;
      margin-bottom: $spacing-md;
      color: $primary-color;
      text-transform: uppercase;
    }

    .image-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: none;

      .image-item {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px;
        img {
        border-radius: $border-radius-sm;
        }

        :hover {
          transform: scale(1.03);
          transition: transform $transition-default;
        }

        .galleryImage {
          width: 100%;
          height: auto;
        }
      }

      @media (max-width: 900px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}

.albumSection {
  background-color: $background-color;
  padding: $spacing-sm;
  border-radius: $border-radius-md;
  max-width: 90%;
  margin: auto;
  text-align: center;

  h2 {
    color: $primary-color;
    font-size: $font-size-large;
    margin-bottom: $spacing-sm;
  }

  .spotify-player {
    iframe {
      height: 400px;
    }
  }
}

@media (max-width: 768px) {
  .albumSection {
    max-width: 100%;
  }
}

.heroSection {
  text-align: center;
  margin: $spacing-lg auto;
  background-color: $background-color;
  padding: $spacing-xs;
  border-radius: $border-radius-md;
  max-width: 90%;

  h3 {
    font-size: $font-size-large;
    text-transform: uppercase;
    color: $primary-color;
  }
  
  .videoWrapper {
    max-width: 100%;
    margin: 0 auto;
    overflow: hidden;

    iframe {
      max-width: 100%;
      height: 600px;

      @media (max-width: 900px) {
        height: 50vh;
      }
    }
  }

  .videoControls {
    margin: $spacing-md auto;
    display: flex;
    justify-content: space-between;
    width: 20%;

    @media (max-width: 900px) {
      width: 90%;
    }
  }
}

.bottomSection {
  margin: $spacing-sm auto;
  max-width: 90%;

  .contact {
    background-color: $background-color;
    padding: $spacing-md;
    border-radius: $border-radius-md;

    h3 {
      text-transform: uppercase;
      font-size: $font-size-large;
      margin-bottom: $spacing-sm;
      color: $primary-color;
      text-align: center;
    }

    .contact-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: auto;

      p {
        display: flex;
        align-items: center;
        gap: $spacing-sm;
        font-size: $font-size-base;
        margin: $spacing-sm 0;

        a {
          color: $text-color-primary;
          text-decoration: none;

          &:hover {
            color: $primary-color;
          }
        }

        svg {
          color: $primary-color;
          margin-right: $spacing-sm;
        }
      }
    }
  }
}

@media (min-width: 900px) {
  .events,
    .gallery {
      width: 48%;
    }
}

@media (max-width: 900px) {
  .topSection {
    flex-direction: column;

    .events,
    .gallery {
      width: 100%;
      margin-bottom: $spacing-md;
    }
  }
}

@media (max-width: 480px) {
  .events, .bottomSection, .heroSection {
    width: 100%;
    padding: none;
    margin: $spacing-lg auto;
  }

  .events h3,
  .gallery h3,
  .contact h3 {
    font-size: $font-size-base;
  }

  .viewMore {
    width: 100%;
    padding: 8px;
  }

  .contact p {
    font-size: $font-size-sm;
  }
}
