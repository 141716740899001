@import "../../../theme";

.scroll-to-top {
  position: fixed;
  bottom: $spacing-lg;
  right: $spacing-lg;
  z-index: 1000;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity $transition-default;

  &:hover {
    opacity: 1;
  }

  .scroll-icon {
    font-size: 50px;
    color: $primary-color;
    background-color: $background-color;
    padding: $spacing-sm;
    border-radius: $border-radius-sm;
    box-shadow: $box-shadow-medium;
  }
}

@media (max-width: 900px) {
  .scroll-to-top {
    bottom: $spacing-md;
    right: $spacing-md;

    .scroll-icon {
      font-size: 50px;
    }
  }
}

@media (max-width: 480px) {
  .scroll-to-top {
    bottom: $spacing-sm;
    right: $spacing-sm;

    .scroll-icon {
      font-size: 45px;
      padding: $spacing-sm;
    }
  }
}
