@import '../../../../theme';

.edit-image-modal {
  display: flex;
  flex-direction: column;
  padding: $spacing-lg;

  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacing-md;
    padding: $padding-sm;
    width: 90%;
    margin: auto;

    label {
      font-family: $font-family-primary;
      font-size: $font-size-sm;
      font-weight: $font-weight-bold;
      color: $text-color-secondary;
      margin-bottom: $spacing-xs;
    }

    .select-container,
    .icon-text-input {
      width: 100%;
      font-size: $font-size-md;
    }

    .icon-text-input {
      padding: $spacing-sm;
      border: 1px solid $disabled-color;
      border-radius: $border-radius-sm;
      transition: border-color $transition-default;

      &:focus {
        border-color: $primary-color;
        outline: none;
      }
    }
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: $spacing-sm;
    margin-top: $spacing-lg;

    button {
      padding: $spacing-sm $spacing-md;
      font-size: $font-size-md;
      border-radius: $border-radius-sm;
      transition: background-color $transition-default;

      &.success {
        background-color: $success-color;
        color: $text-color-primary;

        &:hover {
          background-color: darken($success-color, 10%);
        }
      }

      &.warning {
        background-color: $warning-color;
        color: $text-color-primary;

        &:hover {
          background-color: darken($warning-color, 10%);
        }
      }

      &.error {
        background-color: $error-color;
        color: $text-color-primary;

        &:hover {
          background-color: darken($error-color, 10%);
        }
      }

      &.secondary {
        background-color: $secondary-color;
        color: $text-color-primary;

        &:hover {
          background-color: darken($secondary-color, 10%);
        }
      }
    }
  }
}

/* Responsive Styling */
@media (max-width: 768px) {
  .edit-image-modal {
    padding: $spacing-md;

    .form-group {
      margin-bottom: $spacing-sm;

      label {
        font-size: $font-size-xs;
      }

      .select-container,
      .icon-text-input {
        font-size: $font-size-sm;
      }
    }

    .modal-footer {
      flex-direction: column;
      gap: $spacing-xs;

      button {
        font-size: $font-size-sm;
        width: 100%;
      }
    }
  }
}

@media (max-width: 480px) {
  .edit-image-modal {
    padding: $spacing-sm;

    .form-group {
      .icon-text-input {
        padding: $spacing-xs;
      }
    }
  }
}
