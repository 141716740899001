@import "../../../theme";

.videos-section {
  padding: $spacing-lg;
  background-color: $background-light-color;
  min-height: 100vh;

  .section-header {
    margin-bottom: $spacing-md;
    text-align: center;

    h2 {
      font-family: $font-family-primary;
      font-size: $font-size-large;
      font-weight: $font-weight-bold;
      color: $text-color-secondary;

      @media (max-width: 768px) {
        font-size: $font-size-md;
      }

      @media (max-width: 480px) {
        font-size: $font-size-sm;
      }
    }

    p {
      font-family: $font-family-primary;
      font-size: $font-size-md;
      color: $text-color-secondary;
      margin-top: $spacing-sm;

      @media (max-width: 768px) {
        font-size: $font-size-sm;
      }

      @media (max-width: 480px) {
        font-size: $font-size-xs;
      }
    }
  }

  .add-video-section {
    margin-bottom: $spacing-md;
    display: flex;
    justify-content: center;

    .video-upload {
      width: 100%;
      max-width: 600px;

      @media (max-width: 768px) {
        max-width: 100%;
        padding: $spacing-sm;
      }
    }
  }

  .video-grid-section {
    margin-top: $spacing-md;

    .error-message {
      color: $error-color;
      font-family: $font-family-primary;
      font-size: $font-size-sm;
      text-align: center;
      margin-top: $spacing-md;
    }

    .no-videos-message {
      text-align: center;
      font-family: $font-family-primary;
      font-size: $font-size-md;
      color: $disabled-color;

      @media (max-width: 768px) {
        font-size: $font-size-sm;
      }

      @media (max-width: 480px) {
        font-size: $font-size-xs;
      }
    }

    .video-grid {
        display: flex;
        flex-wrap: wrap;
        gap: $spacing-md; 
        justify-content: center;
        align-items: stretch;
        padding: 0px !important;

        ul {
            padding: none;
        }
      
        li {
          list-style: none;
          width: 300px;
          max-width: 100%;
          height: auto;
          background-color: $background-color-light;
          border-radius: $border-radius-sm;
          box-shadow: $box-shadow-medium;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          transition: transform $transition-default, box-shadow $transition-default;
      
          iframe {
            width: 100%;
            height: 200px;
            border: none;
            border-radius: $border-radius-sm;
      
            @media (max-width: 768px) {
              height: 180px;
            }
      
            @media (max-width: 480px) {
              height: 150px;
            }
          }
      
          p {
            margin-top: $spacing-sm;
            font-family: $font-family-primary;
            font-size: $font-size-sm;
            text-align: center;
            padding: $padding-xs;
            color: $text-color-light;
      
            @media (max-width: 768px) {
              font-size: $font-size-xs;
            }
      
            @media (max-width: 480px) {
              font-size: $font-size-xs;
            }
          }
        }
      }
      
      
  }
}
