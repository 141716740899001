@import '../../../theme';

.progress-bar {
  width: 100%;
  background-color: lighten($background-color, 30%);
  border-radius: $border-radius-md;
  margin: $spacing-sm 0;
  overflow: hidden;

  .progress {
    background-color: $primary-color;
    height: 20px;
    color: $text-color-light;
    text-align: center;
    line-height: 20px;
    transition: width $transition-default;
  }
}
