@import './theme';
@import './Components/Header/Header.scss';

:root {
  --primary-color: #{$primary-color};
  --primary-color-light: #{lighten($primary-color, 10%)};
  --primary-color-hover: #{darken($primary-color, 10%)};
  --primary-text-color: #{$text-color-primary};
  --primary-text-color-contrast: #{$text-color-secondary};
  --secondary-color: #{$secondary-color};
  --secondary-text-color: #{$text-color-secondary};
  --secondary-color-light: #{lighten($secondary-color, 10%)};
  --secondary-color-hover: #{darken($secondary-color, 10%)};
  --background-color: #{$background-color};
  --background-color-dark: #{darken($background-color, 20%)};
  --background-color-darker: #{darken($background-color, 20%)};
  --background-color-light: #{lighten($background-color, 10%)};
  --background-color-lighter: #{lighten($background-color, 20%)};
  --text-color: #{$text-color};
  --text-color-light: #{$text-color-light};
  --link-color: #{$link-color};
  --link-hover-color-primary: #{$link-hover-color-primary};
  --link-hover-color-secondary: #{$link-hover-color-secondary};
}

body {
  font-family: $font-family-primary;
  font-size: $font-size-base;
  background-color: $background-color;
  color: $text-color;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: 0.5s ease-out;
  overflow-x: hidden;
}

.page-container {
  margin: 0px auto;
  width: 100%;
  max-width: 100vw;
  position: relative;
  background-image: url('./Assets/img/eVENTS.jpg');
  background-repeat: repeat;
  background-size: contain;
  min-height: 100vh;

  @media (max-width: 900px) {
    width: 100%;
  }
}

.error-message {
  color: $error-color;
  font-size: $font-size-base;
  margin-top: -2px;
  margin-bottom: -2px;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #555;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
  margin: 1rem auto;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

::-webkit-scrollbar {
  width: 15px; 
}

::-webkit-scrollbar-track {
  background: $background-color; 
}

::-webkit-scrollbar-thumb {
  background-color: $primary-color; 
  border-radius: $border-radius-sm; 
  border: 3px solid $background-color; 
}

::-webkit-scrollbar-thumb:hover {
  background-color: darken($primary-color, 10%);
}

.no-scroll {
  overflow: hidden;
}