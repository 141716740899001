@import '../../../theme';

.button {
  font-family: $font-family-primary;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  padding: $spacing-xs $spacing-sm;
  border-radius: $border-radius-sm;
  transition: $transition-default;
  border: none;
  margin: $spacing-xs;
  cursor: pointer;
  color: $text-color-dark;

  &.primary {
    background-color: $primary-color;
  }

  &.secondary {
    background-color: $secondary-color;
  }

  &.warning {
    background-color: $warning-color;
    color: $text-color;
  }

  &.error {
    background-color: $error-color;
    color: $text-color;
  }

  &.success {
    background-color: $success-color;
    color: $text-color;
  }

  &:hover {
    background-color: darken($primary-color, 30%);
    transform: scale(1.1);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }

  &.disabled {
    background-color: $disabled-color;
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.6;
  }

  &:focus {
    outline: 2px solid $disabled-color;
    outline-offset: 2px;
  }
}
