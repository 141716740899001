@import "../../../../theme";

.request-booking-modal {
  width: 95%;
  height: 95%;
  margin: 0 auto;
  box-sizing: border-box;
  text-align: center;



  p {
    font-size: $font-size-sm;
    line-height: 1.5; // Improve readability
    margin-bottom: $spacing-sm;
  }

  .form-group {
    margin-bottom: $spacing-md;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;

    @media (max-width: 600px) {
      text-align: center;
    }


    .additional-services {
      display: flex;
      flex-direction: row;
      gap: $padding-lg;

      @media (max-width: 600px) {
        flex-direction: column;
      }
    }

    label {
      display: block;
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
      margin-bottom: $spacing-xs;
    }

    input,
    textarea {
      width: 100%;
      padding: $padding-sm;
      border: 1px solid $text-color-light;
      border-radius: $border-radius-sm;
      font-family: $font-family-primary;
      font-size: $font-size-base;
      transition: border-color $transition-default;

      &:focus {
        border-color: $primary-color;
        outline: none;
      }
    }

    textarea {
      resize: none;
    }

    p {
      margin-top: $spacing-xs;
      font-size: $font-size-sm;
      text-align: center;
      color: var(--text-color-secondary);
    }


    .error-text {
      color: $error-color;
    }
  }
}