@import "../../../theme";

.custom-accordion {
    border-radius: $border-radius-md;
    overflow: hidden;
    margin-bottom: $spacing-sm;
    transition: box-shadow $transition-default;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: $spacing-xs;
        font-size: $font-size-base;
        font-weight: $font-weight-bold;
        cursor: pointer;
        border: none;
        outline: none;
        background-color: none;
        transition: background-color $transition-default;

        &.primary {
            background-color: $primary-color;

            &:hover {
                background-color: darken($primary-color, 20%);
            }
        }

        &.secondary {
            color: var(--text-color);
            background-color: var(--secondary-color);
        }

        &.success {
            background-color: $success-color;
        }

        &.error {
            background-color: $error-color;
        }

        &.warning {
            background-color: $warning-color;
        }

        &.clear {
            color: var(--text-color-primary);
            background-color: transparent;
        }

        &.disabled {
            background-color: $disabled-color;
            cursor: not-allowed;
            pointer-events: none;
            opacity: 0.6;
        }
    }


    &__title {
        flex-grow: 1;
    }

    &__icon {
        font-size: 1.5rem;
        transition: transform $transition-default;

        &.expanded {
            transform: rotate(180deg);
        }
    }

    &__content {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease, padding $transition-default;
        padding: 0 $spacing-md;
        background-color: var(--background-color-light);

        &.clear {
            background-color: transparent;
        }

        &.expanded {
            max-height: 800px;
            padding: $spacing-sm $spacing-md;
        }
    }
}