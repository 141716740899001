@import "../../../theme";

.pagination {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  max-width: 90%;
  margin: 20px auto;
  justify-content: center;
  align-items: center;

  .pagination-controls {
    display: flex;
    align-items: center;
    color: $text-color;
  }

  .pagination-buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 6px;

    .pagination-button {
      margin: 2px;
      &:hover:not(:disabled),
      &.active {
        background-color: var(--secondary-color);
        color: var(--background-color);
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }

    .pagination-dots {
      color: var(--text-color-primary);
      font-size: $font-size-base;
      margin: 0 6px;
    }

    .view-more {
      background-color: var(--primary-color);
      color: var(--background-color);
      font-size: $font-size-sm;
      padding: $spacing-xs $spacing-sm;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }
  }

  @media (max-width: 768px) {
    gap: 8px;
    .pagination-controls {
      order: -1;
      gap: 8px;
    }
    .pagination-buttons {
      margin-top: 4px;
      width: 100%;
    }
  }

  @media (max-width: 480px) {
    flex-direction: row;
    gap: 4px;
    .pagination-controls {
      gap: 5px;
    }
    .pagination-buttons {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      gap: 4px;
      justify-content: center;
      width: 100%;

      .pagination-button {
        font-size: 12px;
      }
    }
  }
}
