@import "../../../theme";

.alert {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: $spacing-sm $spacing-md;
  border-radius: $border-radius-md;
  font-family: $font-family-primary;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  color: $text-color-primary;
  box-shadow: $box-shadow-medium;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 300px;
  z-index: 2002;
  transition: opacity $transition-default, transform $transition-default;

  &-success {
    background-color: $success-color;
  }

  &-error {
    background-color: $error-color;
  }

  &-warning {
    background-color: $warning-color;
  }

  &-info {
    background-color: $background-secondary-light-color;
    color: $text-color-dark;
  }

  &-message {
    flex-grow: 1;
  }

  &-close {
    background: none;
    border: none;
    color: $text-color;
    font-size: $font-size-large;
    cursor: pointer;
    margin-left: $spacing-xs;
    padding: 0;

    &:hover {
      color: $link-hover-color-primary;
    }
  }

  &:hover {
    opacity: 1;
  }
}
