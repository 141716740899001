@import "../../theme";

.notFound-outline {
  text-align: center;
  background-color: $background-color;
  padding: $spacing-md;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow-medium;
  max-width: 600px;
  margin: auto;
  animation: fadeIn 0.5s ease-in-out;

  @media (max-width: 768px) {
    padding: $spacing-sm;
  }
}

.notFound-heading {
  font-size: $font-size-large;
  color: $text-color-primary;
}

.dog-icon {
    margin: -10px;
    font-size: 5em;
}

.notFound-message {
  font-size: $font-size-base;
  color: $text-color-primary;
  margin: $spacing-sm 0;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
