@import '../../theme';

.image-upload {
  margin-bottom: $spacing-lg;
  padding: $spacing-md;
  border-radius: $border-radius-md;
  margin: 10px auto;
  text-align: center;

  h2 {
    margin-bottom: $spacing-sm;
  }

  .form-group {
    margin-bottom: $spacing-md;

    .file-input {
      padding: $spacing-sm;
      font-size: $font-size-base;
      color: $text-color-light;
      border: 1px solid lighten($text-color-primary, 40%);
      border-radius: $border-radius-sm;
      width: 100%;
    }
  }
}

.success-message {
  color: white;
}

@media (max-width: 1024px) {
  .image-upload {
    padding: $spacing-sm;
    max-width: 90%;

    h2 {
      font-size: 1.75rem;
    }
  }
}

@media (max-width: 768px) {
  .image-upload {
    padding: $spacing-sm;
    max-width: 100%;

    h2 {
      font-size: 1.5rem;
    }
  }
}

@media (max-width: 480px) {
  .image-upload {
    padding: $spacing-sm;
    max-width: 100%;

    h2 {
      font-size: 1.25rem;
    }

    button {
      font-size: $font-size-sm;
      padding: $spacing-sm $spacing-md;
    }
  }
}
