@import '../../theme';

.gallery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;

  .gallery-header {
    text-align: center;
    padding: $spacing-sm;
    background-color: $background-color;
    border-radius: $border-radius-sm;
    box-shadow: $box-shadow-large;
    max-width: 800px;
    margin-bottom: $spacing-md;
    z-index: 1;

    h1 {
      font-size: 2rem;
      color: $primary-color;
      margin-bottom: $spacing-sm;
    }

    p {
      font-size: $font-size-base;
      color: darken($text-color-primary, 10%);
    }
  }

  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: $spacing-sm;
    width: 100%;
    justify-content: center;
    padding: $spacing-sm;
    overflow-y: auto;

    .gallery-item {
      display: flex;
      justify-content: center;
      overflow: hidden;
      transition: transform $transition-default, box-shadow $transition-default;
      min-height: 200px;
      max-width: 400px;

      img {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        min-height: 200px;
        height: auto;
        object-fit: cover;
        transition: transform $transition-default;
        border: 2px solid $text-color-light;
      }

      &:hover {

        img {
          transform: scale(1.1);
          border: none;
        }
      }
    }
  }

  .no-image-container {
    background-color: $background-color;
    padding: $padding-lg;
    border-radius: $border-radius-lg;
    box-shadow: $box-shadow-large;
    width: 50%;
    text-align: center;
    height: 50%;
    margin: 20px;
    color: white;
  }
}
