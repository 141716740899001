@import "../../../theme";

.loading-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  animation: fade-in 1s ease-in-out;
}

.bookings-section {
  padding: $spacing-lg;
  background-color: $background-light-color;
  min-height: 100vh;

  .booking-requests {
    text-align: center;
  }

  .section-header {
    margin-bottom: $spacing-md;
    text-align: center;

    h2 {
      font-family: $font-family-primary;
      font-size: $font-size-large;
      font-weight: $font-weight-bold;
      color: $text-color-secondary;

      @media (max-width: 768px) {
        font-size: $font-size-md;
      }

      @media (max-width: 480px) {
        font-size: $font-size-sm;
      }
    }

    p {
      font-family: $font-family-primary;
      font-size: $font-size-md;
      color: $text-color-secondary;
      margin-top: $spacing-sm;
      text-align: center;

      @media (max-width: 768px) {
        font-size: $font-size-sm;
      } 

      @media (max-width: 480px) {
        font-size: $font-size-xs;
      }
    }
  }
  
  .pending-bookings, .approved-bookings {
    text-align: center;
  }

  .section {
    margin-bottom: $spacing-lg;

    h3 {
      font-family: $font-family-primary;
      font-size: $font-size-large;
      font-weight: $font-weight-bold;
      color: $text-color;
      margin-bottom: $spacing-md;
      text-align: center;
    }
    

    ul {
      list-style: none;
      padding: 0;

      li {
        border: 1px solid $background-color-dark;
        border-radius: $border-radius-md;
        padding: $spacing-md;
        margin-bottom: $spacing-md;
        background-color: $background-color;
        max-width: 800px;
        margin: $padding-sm auto;
        box-shadow: $box-shadow-large;

        .booking-info,
        .event-info {
          p {
            margin: $spacing-xs 0;
            font-family: $font-family-primary;
            font-size: $font-size-md;
            color: $text-color-light;
            text-align: center;

            strong {
              font-weight: $font-weight-bold;
            }
          }

          a {
            color: $link-color;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }

          strong {
            color: $text-color-light;
            text-align: center;
          }
        }

        .booking-actions {
          display: flex;
          justify-content: flex-end;
          gap: $spacing-xs;
        }
      }
    }
  }

  .error-message {
    text-align: center;
    color: $error-color;
    font-family: $font-family-primary;
    font-size: $font-size-md;
    margin-top: $spacing-md;
  }
}
