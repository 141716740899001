@import '../../theme';

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('../../Assets/img/eVENTS.jpg');
  background-repeat: repeat;
  background-size: contain;
  transition: $transition-default;
}

.login-container {
  background-color: $background-color;
  padding: $spacing-lg;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow-medium;
  width: 100%;
  max-width: 600px;
  text-align: center;

  .login-title {
    font-size: 2rem;
    margin-bottom: $spacing-md;
    color: $primary-color;
  }

  .login-error {
    color: $error-color;
    margin-bottom: $spacing-sm;
    font-size: $font-size-sm;
    margin: 2px;
  }

  .login-form {
    display: flex;
    flex-direction: column;

    .form-group {
      margin-bottom: $spacing-md;

      label {
        display: block;
        font-size: $font-size-base;
        color: $text-color-primary;
        margin-bottom: $spacing-sm;
      }
    }
  }
}
