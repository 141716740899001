@import "../../../../theme";

.view-booking-modal {

  &__content {
    font-family: $font-family-primary;
    color: $text-color-light;
    text-align: center;

    strong {
        color: $text-color-light !important; 
    }

    p {
      font-size: $font-size-md;
      margin-bottom: $spacing-sm;

      strong {
        font-weight: $font-weight-bold;
        color: $text-color-dark;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0 0 $spacing-md;

      li {
        font-size: $font-size-sm;
        margin-bottom: $spacing-xs;

        a {
          color: $link-color;
          text-decoration: $link-text-decoration;

          &:hover {
            color: $link-hover-color-primary;
            text-decoration: $link-hover-decoration;
          }
        }
      }
    }
  }

  .view-booking-modal__footer {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 800px) {
      flex-direction: column;
    }
  }
}
